import React, { useEffect } from "react";
import logo from './images/logohead-.png';
import attName from './images/attractionsName.png';

export default function Products() {

  useEffect(() => {
    window.scroll(0,0);
  },[]);

  return (
    <>
    <div id="menu">
        <div id="menuDiv">
          <img src={attName} width="70%" alt="" />
          <h4>Price List</h4>
          <div style={{textAlign: "center"}}>
            <table>
                <tr><td className="tdLeft">Womens Hair Cut</td><td className="tdRight">$30+</td></tr>
                <tr><td className="tdLeft">Mens Hair Cut</td><td className="tdRight">$18+</td></tr>
                <tr><td className="tdLeft">Blowdry</td><td className="tdRight">$30</td></tr>
                <tr><td className="tdLeft">Updo</td><td className="tdRight">$65+</td></tr>
                <tr><td className="tdLeft">Color</td><td className="tdRight">$50+</td></tr>
                <tr><td className="tdLeft">Full Highlight w/ Blowdry</td><td className="tdRight">$105+</td></tr>
                <tr><td className="tdLeft">Color Highlight w/ Blowdry</td><td className="tdRight">$150+</td></tr>
                <tr><td className="tdLeft">Partial Highlight w/ Blowdry</td><td className="tdRight">$85+</td></tr>
                <tr><td className="tdLeft">Partial Highlight w/ Color w/ Blowdry</td><td className="tdRight">$135+</td></tr>
                <tr><td className="tdLeft">Toner</td><td className="tdRight">$30+</td></tr>
                <tr><td className="tdLeft">Additional Color</td><td className="tdRight">$30</td></tr>
                <tr><td className="tdLeft">Perms</td><td className="tdRight">$100</td></tr>
                <tr><td className="tdLeft">Waxes | Eyebrow, Lip, Chin</td><td className="tdRight">$13</td></tr>
                <tr style={{height: "20px"}}></tr>
                <tr><td className="tdLeft">City Manicure</td><td className="tdRight">$25</td></tr>
                <tr><td className="tdLeft">Shellac Manicure</td><td className="tdRight">$35</td></tr>
                <tr><td className="tdLeft">Pedicure</td><td className="tdRight">$45</td></tr>
                <tr><td className="tdLeft">Shellac Pedicure</td><td className="tdRight">$55</td></tr>
            </table>
          </div>
          <div><img src={logo} width="40px" style={{margin: "15px"}} alt="" /></div>
          <div style={{marginBottom: "15px", fontSize: "12px"}}>
            <p>810 N. Water Street<br/>Bay City, MI 48708</p>
            <div><b>Monday to Thursday: </b><span className="plain">10:00 AM - 7:00 PM </span></div>
            <div><b>Friday: </b><span className="plain">9:00 AM - 5:00 PM</span></div>
            <div><b>Saturday: </b><span className="plain">Call          
              <a href="tel:9897998988"> (989) 799-8988 </a> 
            for Availability</span></div>
            <div><b>Sunday: </b><span className="plain">Closed</span></div>
          </div>
        </div>
    </div>
    </>
  )
}
