import React from 'react';

function handleClose() {
    let mod = document.getElementById("contactMod");
    mod.style.display = "none";
    document.getElementById("files").value = "";
    document.getElementById("sc-openSidenav").style.display = "blocK";
}

function LoadingSpinner() {
    return (
      <div className="spinner-container">
        <div className="loading-spinner">
        </div>
      </div>
    );
  }

export default function Apply () {
    const [email, setEmail] = React.useState('');
    const [Fname, setFname] = React.useState('');
    const [Lname, setLname] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [msg, setMsg] = React.useState('');

    function submitContact(event){
        event.preventDefault();
        setIsLoading(true);
        let x = msg.replaceAll('\n', '<br>');
        const files = document.getElementById("files");
        const formData = new FormData();
        formData.append("fname", Fname);
        formData.append("lname", Lname);
        formData.append("phone", phone);
        formData.append("email", email);
        //formData.append("to", "attractionshairnailsalon2013@gmail.com");
        formData.append("to", "dev@13media13.com"); //for testing only
        formData.append("bcc", "dev@13media13.com");
        formData.append("from","new_applicant@attractionshairnailsalon.com");
        formData.append("subject", "Attractions Salon has received a new application from the website!");
        formData.append("message", x)
        for(let i =0; i < files.files.length; i++) {
                formData.append("files", files.files[i]);
        }
        const url = "https://api.13media13.com/attractions/apply"
        //const url = "http://localhost:5000/attractions/apply"
        fetch(url, {
            method: 'POST',
            body: formData,
            headers: { 
              
            }
        })
            .then((res) => {
                console.log(res.status)
                if (res.status === 200) {
                    setIsLoading(false); 
                    document.getElementById("sc-openSidenav").style.display = "blocK";
                    alert("Thank you for your interest. You Application has been sent.");
                    setFname('');setLname('');setPhone('');setEmail('');setMsg('');
                    handleClose();
                } else {
                    setIsLoading(false);
                    alert("An unexpected error occured. Please try again"); 
                    return;
                }
            })
            .catch((err) => (err));
    }


    return (
        <>
        <div id="contactMod" className="contactModal">
        {isLoading ? <LoadingSpinner /> : 
            <div className="modal-content">
            <div className="close" onClick={handleClose}>&times;</div>
                <div style={{marginTop: "20px", textAlign: "center"}}>
                    <div style={{marginRight: "50px", marginLeft: "50px", margintop: "15px", marginBottom: "15px"}}>Thank you for your interest in employment at Attractions Salon! Please fill out the below and attach your resume! We look forward to getting to know you and discussing your application!
                    </div>
                    <form className="container" onSubmit={submitContact}>
                        <input type="text" value={Fname} onChange={e =>setFname(e.currentTarget.value)} required placeholder="First Name.."/>
                        <input type="text" value={Lname} onChange={e =>setLname(e.currentTarget.value)} required placeholder="Last Name.."/><br/>
                        <input type="text" value={phone} onChange={e =>setPhone(e.currentTarget.value)} required placeholder="Phone Number." /><br/>
                        <input type="email" value={email} onChange={e =>setEmail(e.currentTarget.value)} required placeholder="Your Email.." /><br/>
                        <input type="file" id="files" style={{backgroundColor: "white"}} required /><br/>
                        <textarea id="subject" value={msg} onChange={e => setMsg(e.currentTarget.value)} placeholder="Additional Comments.." style={{minHeight: "200px"}}></textarea>
                        <input className="btn1" type="submit" value="Apply!"/>
                    </form>
                </div>
            </div>}
        </div>
        </>
    )
}