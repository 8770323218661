import React from "react";
import left from './images/homeLeft.png';
import center from './images/homeCenter.png';
import right from './images/homeRight.png';
import Promo from './promo';

export default function Home(){ 
  return (
    <>
    <div id="homeHead" style={{textAlign: "center", marginTop: "60px"}}>
        <img className="homeImg1" src={left} style={{margin: "2px"}} width="27%" alt="" />
        <img className="homeImg2" src={center} style={{margin: "2px"}}  width="27%" alt="" />
        <img className="homeImg3"src={right} style={{margin: "2px"}}  width="27%" alt="" />
    </div>
  <div id="aboutdiv" style={{marginBottom: "60px", textAlign: "center"}}>
    <Promo />
      <div>
        <h4>Hours &#38; Location </h4>
        <p>810 N. Water Street<br/>Bay City, MI 48708</p>
          <div><b>Monday to Thursday: </b><span className="plain">10:00 AM - 7:00 PM </span></div>
          <div><b>Friday: </b><span className="plain">9:00 AM - 5:00 PM</span></div>
          <div><b>Saturday: </b><span className="plain">Call for Availability</span></div>
          <div><b>Sunday: </b><span className="plain">Closed</span></div>
      </div>

  </div>
  <div style={{marginTop: "30px", textAlign: "center"}}>
      <iframe title="locMap" id="googMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.675902880791!2d-83.8919144842108!3d43.598739063968324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8823c3a1ff2cc2db%3A0x3be62cb445e394ec!2sAttractions%20Salon!5e1!3m2!1sen!2sus!4v1645460982357!5m2!1sen!2sus" width="700" height="375" style={{border: "0", borderRadius: "10px", marginBottom: "75px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45)"}} loading="lazy"></iframe>
      </div>

    </>
  );  
}