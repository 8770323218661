import React, { useEffect } from "react";
import hair from './images/hair.jpg';
import hair2 from './images/hair2.jpg';
import hair3 from './images/hair3.jpg';
import hair4 from './images/hair4.jpg';
import hair5 from './images/hair5.jpg';
import hair6 from './images/hair6.jpg';
import hair7 from './images/hair7.jpeg';
import hair8 from './images/hair8.jpeg';
import hair9 from './images/hair9.jpeg';
import hair10 from './images/hair10.jpeg';
import hair11 from './images/hair11.jpeg';
import hair12 from './images/hair12.jpeg';
import hair13 from './images/hair13.jpeg';
import nails from './images/nails.jpg';
import nails2 from './images/nails2.jpg';
import nails3 from './images/nails3.jpg';
import nails4 from './images/nails4.jpg';
import nails5 from './images/nails5.jpg';
import nails6 from './images/nails6.jpg';
import nails7 from './images/nails7.jpg';
import nails8 from './images/nails8.jpeg';
import nails9 from './images/nails9.jpeg';
import nails10 from './images/nails10.jpeg';
import nails11 from './images/nails11.jpeg';
import nails12 from './images/nails12.jpeg';
import nails13 from './images/nails13.jpeg';
import nails14 from './images/nails14.jpeg';
import nails15 from './images/nails15.jpeg';
import hair14 from './images/h20340.jpeg';
import nails16 from './images/n20336.jpeg';
import nails17 from './images/n20338.jpeg';
import prod1 from './images/prod1.JPG'
import prod2 from './images/prod2.JPG'

export default function Gal() {

    useEffect(() => {
        window.scroll(0,0);
      },[]);


    return (
        <>
        <div style={{marginTop: "75px", marginBottom: "75px"}}>
            <div className="row">
                <div className="column">
                <img className="prodImg" src={hair} alt="" />
                <img className="prodImg" src={nails} alt="" />
                <img className="prodImg" src={nails16} alt="" />
                <img className="prodImg" src={hair7} alt="" />
                <img className="prodImg" src={hair11} alt="" />
                <img className="prodImg" src={nails2} alt="" />
                <img className="prodImg" src={nails4} alt="" />
                <img className="prodImg" src={nails3} alt="" />
                </div>
                <div className="column">
                <img className="prodImg" src={hair2} alt="" />
                <img className="prodImg" src={hair3} alt="" />
                <img className="prodImg" src={nails12} alt="" />
                <img className="prodImg" src={hair8} alt="" />
                <img className="prodImg" src={nails11} alt="" />
                <img className="prodImg" src={hair14} alt="" />
                <img className="prodImg" src={nails9} alt="" />
                </div>
                <div className="column">
                <img className="prodImg" src={hair4} alt="" />
                <img className="prodImg" src={hair5} alt="" />
                <img className="prodImg" src={nails8} alt="" />
                <img className="prodImg" src={hair13} alt="" />
                <img className="prodImg" src={nails13} alt="" />
                <img className="prodImg" src={nails10} alt="" />
                <img className="prodImg" src={nails6} alt="" />
                </div>
                <div className="column">
                <img className="prodImg" src={nails7} alt="" />
                <img className="prodImg" src={hair6} alt="" />
                <img className="prodImg" src={hair9} alt="" />
                <img className="prodImg" src={nails5} alt="" />
                <img className="prodImg" src={hair10} alt="" />
                <img className="prodImg" src={nails14} alt="" />
                <img className="prodImg" src={nails17} alt="" />
                </div>
            </div>
        </div>
        </>
    )
}