import React from 'react';
import logo from './images/logohead_.png';

function myFunction(event) {
  var x = document.getElementById("centered_nav");
  if (x.className === "rc_nav") {
      x.className += " responsive";
  } else {
      x.className = "rc_nav";
  }
}

export default function NavBar(){

return (
    <>
    <div className="rc_nav" id="centered_nav">
      <a href="/#" title="Logo" id="rc_logo">
            <img src={logo} style={{width: "33px", filter: "invert(100%"}} title="Home" alt="" /> 
      </a> 
      <a href="#/products/" id="products" onClick={myFunction}>Our Services </a>
      <a href="#/gal/" id="gal" onClick={myFunction}>Our Work</a>
      <a href="/#" title="Logo" id="fullHome">
            <img src={logo} style={{width: "33px", filter: "invert(100%"}} title="Home" onClick={myFunction} alt="" /> 
      </a>
      <a href="#/bio/" id="bio" onClick={myFunction}>Our Team</a>
      <a href="#/about/" id="about" onClick={myFunction}>Our Story</a>
      <a title="Menu" style={{fontSize: "18px"}} className="icon" onClick={myFunction}>&#9776;</a>
    </div>
</>
  )
}
 