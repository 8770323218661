import React from 'react';

function handleClose() {
    let mod = document.getElementById("contact");
    mod.style.display = "none";
}

function LoadingSpinner() {
    return (
      <div className="spinner-container">
        <div className="loading-spinner">
        </div>
      </div>
    );
  }

export default function Contact () {
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [msg, setMsg] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    const submitContact = (event) => {
        setIsLoading(true);
        let x = msg.replaceAll('\n', '<br>');
        var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "from": "contact@attractionssalon.com",
                //"to": "snoopprophet@gmail.com; attractionshairnailsalon2013@gmail.com;",
                "to": "snoopprophet@gmail.com",
                "bcc": "dev@13media13.com",
                "domain": "attractionssalon.com",
                "subject":"Attractions Salon has received a new contact request",
                "name": name,
                "email": email,
                "message": x
            }),
            redirect: 'follow'
            };
            //const url = `https://api1313media.herokuapp.com/contact`;
            const url = `https://api.13media13.com/contact`;
            //const url = `http://localhost:5000/contact`;
            fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result.status)
                if (result.status === "Success") {
                    setIsLoading(false); 
                    alert("Thank you for your interest. You message has been sent.");
                    setName('');setEmail('');setMsg('');
                    handleClose();
                } else {
                    setIsLoading(false); 
                    alert("An unexpected error occured. Please try again"); 
                    return;
                }
            })
            .catch(error => console.log('error', error))
            event.preventDefault();
    }

    return (
        <>
        <div id="contact" className="contactModal">
        {isLoading ? <LoadingSpinner /> : 
            <div className="modal-content">
            <div className="close" onClick={handleClose}>&times;</div>
                <div style={{marginTop: "20px", textAlign: "center"}}>
                    <div style={{marginRight: "50px", marginLeft: "50px", margintop: "15px", marginBottom: "15px"}}>We would love to hear from you! Submit any questions or comments to the below form to get in touch with us!
                    </div>
                    <form className="container" onSubmit={submitContact}>
                        <input type="text" value={name} onChange={e =>setName(e.currentTarget.value)} required placeholder="First and Last Name.." /><br/>
                        <input type="email" value={email} onChange={e =>setEmail(e.currentTarget.value)} required placeholder="Your Email.." /><br/>
                        <textarea id="subject" value={msg} onChange={e => setMsg(e.currentTarget.value)} required placeholder="Your Message.." style={{minHeight: "200px"}}></textarea>
                        <input className="btn1" type="submit" value="Submit"/>
                    </form>
                </div>
            </div>}
        </div>
        </>
    )
}