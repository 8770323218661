import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import NavBar from './navbar';
import Home from './home';
import About from './about';
import Bio from './bio';
import Products from './products';
import { HashRouter, Route, Routes } from "react-router-dom";
import Footer from './footer';
import Apply from './apply';
import Gal from './gal';
import Contact from './contact.js';

function Spa() {
  return (
    <HashRouter>
      <NavBar/>
      <Apply/>
      <Contact />
        <div className="container" style={{padding: "20px"}}>
          <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/gal" exact element={<Gal/>} />
          <Route path="/bio/" element={<Bio/>} />
          <Route path="/products/" element={<Products/>} />
          <Route path="/about/" element={<About/>} />
          </Routes>
        </div> 
        <Footer />           
    </HashRouter>
  ); 
}
ReactDOM.render(<Spa/>, document.getElementById('root'));

