import React, { useEffect } from "react";
import userimg from './images/teamplaceholder.png';
import phoebe from './images/phoebe.jpeg';
import connie from './images/connie.jpeg';

function beginApply() {
  let mod = document.getElementById("contactMod");
  mod.style.display = "block";
  document.getElementById("sc-openSidenav").style.display = "none";
}



export default function Bio(){ 

  useEffect(() => {
    window.scroll(0,0);
  },[]);

  return (
    <>
    <center>
    <div id="biodiv">
    <button className="btn1" id="apply" style={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45)"}} onClick={beginApply }>Join Our Team!</button>
    <div className="bioSection">
          <h2>Phoebe</h2>
            <p><img id="bioImg" src={phoebe} style={{float: "right", boxShadow: "10px 8px 10px 3px rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45)"}} alt=""/>Hello! I am a lifetime resident of Reese MI and graduated from RHS in 2013. I enjoy hunting, fishing, traveling, anything outdoors, and of course my love of hair and nails. I graduated from the Beauty Institute of Birch Run, and joined the Attractions team in 2021!<br/>Thank you for taking the time to read and get to know me, and I hope to see you soon. Stop in or call us to book an appointment.</p>
      </div>
      <div className="bioSection">
          <h2>Connie Jo</h2>
            <p><img id="bioImg" src={connie} style={{float: "left", boxShadow: "-10px 8px 10px 3px rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45)"}} alt=""/>My name is Connie Jo and I have been a stylist for over 35 years. I have been working at Attractions Salon since it first opened 27 years ago. <br/>My favorite part of being a stylist is fostering relationships with clients over time. I also enjoy the challenge of coloring and cutting hair to meet my clients’ needs. One of my newer passions is formal hairstyles. I’m happy serving the spectrum of hair needs – from someone’s first cut to making a bride and her wedding party feel and look amazing.
            <br/>In my spare time I love to travel with my two sons and relax on our boat with my husband. I also love to bake and workout with my fit family to stay healthy."
            </p>
      </div>
      <div style={{marginBottom: "150px"}}>
          <h2>Anotherperson</h2>
            <p><img src={userimg} style={{width: "200px", float: "right", margin: "15px", borderRadius: "100%", boxShadow: "10px 8px 10px 3px rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45)"}} alt=""/>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
      </div>
      <div style={{marginBottom: "200px"}}>
          <h2>Lastperson</h2>
            <p><img src={userimg} style={{width: "200px", float: "left", margin: "15px", borderRadius: "100%", boxShadow: "-10px 8px 10px 3px rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45)"}} alt=""/>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
      </div>
    </div>
    </center>
    </>
  );  
}