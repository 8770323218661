import React from "react";
import xmas from './images/xmas.jpg';

export default function Promo() {

    return (
        <>
            <div>
                <img src={xmas} className="promo" alt=""/>
            </div>
        </>
    )
}