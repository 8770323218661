import React from "react";

export default function About(){ 
  return (
    <div id="aboutdiv">
        <h1 style={{textAlign: "center", marginBottom: "15px"}} >Achieve the Look You Desire</h1>
        <h2 style={{textAlign: "center"}}>Over 20 Years of Excellent Hair and Nail Care</h2>
        <p>For over 20 years, we have been providing the residents of Mid-Michigan with outstanding services. The team of experienced stylists and manicurists are trained to boost your confidence with a new hair style or unwind with a refreshing nail care service.<br/></p>
        <p><br/></p>
        <h2 style={{textAlign: "center"}} >Be the Best Version of Yourself Today</h2>
        <p>Be the best version of yourself today. Call us to schedule an appointment with some of the best hair and nail technicians in the area. Feel free to visit us during our service hours. We are happy to help you!<br/></p>
        <p><br/></p>
</div>
  );  
}
