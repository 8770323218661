import React from "react";
import mail from './images/mail.png';
import fb from './images/1200px-Facebook_icon.svg.png';
import phone from './images/phone.png';
import insta from './images/instagram.png';


const submitContact = (event) => {
  let mod = document.getElementById("contact");
  mod.style.display = "block"
  event.preventDefault();
}

export default function Footer(){ 
  return (
    <div className="footer">
			<div id="contact">
				<a href="" onClick={submitContact}><img className="img1" src={mail}width="30px" alt="" /></a>
				<a href="tel:+1-989-799-8988"><img className="img1" src={phone} style={{marginLeft: "10px", width: "30px"}} alt="" /></a>
                <a href="https://www.instagram.com/attractionshairnailsalon/" target="_blank" rel="noreferrer" ><img className="img1" src={insta}  style={{marginLeft: "10px", width: "30px"}} alt="" /></a>
                <a href="https://www.facebook.com/AttractionsHairNailSalon/#" target="_blank" rel="noreferrer" ><img className="img1" src={fb}  style={{marginLeft: "10px", width: "30px"}} alt="" /></a>
                <br/><span style={{fontSize: "13px"}}>© 2022 Attractions Salon. All rights reserved.</span>
			</div>
    </div>
  );  
}